@tailwind base;
@tailwind components;
@tailwind utilities;

.App {
  text-align: center;
}
.navBarWrapper {
  width: 100%;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 100;
}
.movingPhone {
  display: inline-block;
  animation: hi 2s infinite;
  margin-left: 0.5rem;
}
.movingPhone img {
  height: 1.5rem;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/* @media screen and (max-width: 992px) {
  body {
    background-color: blue;
  }
} */
