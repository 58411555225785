.homeDiv {
  height: 30rem !important;
}
.movingHand {
  display: inline-block;
  animation: hi 2s infinite;
}

@keyframes hi {
  50% {
    transform: rotate(45deg);
  }
}
@media screen and (max-width: 600px) {
  .max-w-lg {
    max-width: 18rem;
  }

  .homeDiv {
    height: 30rem !important;
  }
  .homeImage {
    width: 18rem;
  }
  .homeName {
    font-size: 36px;
  }
}
